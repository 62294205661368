<template>
  <v-card>
    <v-card-title>Do you want to update clearance status for {{ idsToChangeClearanceStatus.length > 1 ? 'these items' : 'this item' }}
      ?
    </v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processChangeClearanceStatus">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'ChangeClearanceStatus',
  props: ['idsToChangeClearanceStatus', 'onChangedClearanceStatus', 'onCancel'],
  methods: {
    ...mapActions({
      changeClearanceStatus: 'big_container/changeClearanceStatus'
    }),
    async processChangeClearanceStatus() {
      if (!this.idsToChangeClearanceStatus) return this.noticeWarning('Item not found');
      this.changeClearanceStatus({
        ids: this.idsToChangeClearanceStatus,
        status: this.CONSTANTS.ORDER_CLEARANCE_STATUS.PASSED
      }).then(() => {
        this.onChangedClearanceStatus();
      });
    }
  }
}
</script>
