<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Update BigContainer</v-card-title>
    <v-row>

      <v-col cols="6"><v-text-field dense outlined label="Name" v-model="item.name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Code" v-model="item.code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="TrackingNumber" v-model="item.tracking_number"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Carrier" v-model="item.carrier"/></v-col>
      <v-col cols="12"><v-text-field dense outlined label="Supplier" v-model="item.supplier"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Status" v-model="item.status"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="RegisterTrackingStatus" v-model="item.register_tracking_status"/></v-col>
      <v-col cols="6">
        <v-select v-model="item.clearance_status" label="Clearance Status" :items="listClearanceStatus.filter(item => item.value !== '')" dense outlined></v-select>
      </v-col>
      <v-col cols="6"><v-text-field type="datetime-local" dense outlined label="Clearance Date" v-model="item.clearance_date"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Note" v-model="item.note"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Weight" v-model="item.weight"/></v-col>

    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processUpdate">Update</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'UpdateBigContainer',
  props: ['selectedItem', 'onUpdated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  computed: {
    ...mapGetters({
      listClearanceStatus: "order/listClearanceStatus",
    })
  },
  methods: {
    ...mapActions({
      updateItem: 'big_container/update',
    }),
    processUpdate() {
      this.updateItem(this.item).then(() => {
        this.onUpdated();
      });
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
