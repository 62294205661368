<template>
  <div class="add-big-container-fee">
    <div class="title-col title-order font-weight-bold color-main my-4">
      Thêm phí vận chuyển
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            style="font-size: 2.25rem; margin-left: 1rem"
            v-on="on"
            @click="addBigContainerFee"
            color="success"
          >mdi-plus
          </v-icon>
        </template>
        <span>Ấn vào đây để thêm phí vận chuyển</span>
      </v-tooltip>
    </div>
    <div v-if="fees.length > 0" class="py-3 px-3 table-order-fees">
      <v-row class="v-data-table v-card v-sheet v-sheet--outlined theme--light">
        <v-col cols="12" class="v-data-table__wrapper">
          <table>
            <thead>
            <tr>
              <th>No</th>
              <th>Fee name</th>
              <th>Amount</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(fee, idx) in fees" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <v-text-field
                  v-model="fee.fee_name"
                  dense
                  label="Tên chi phí"
                  outlined
                  :disabled="!fee.not_save"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="fee.fee_amount"
                  dense
                  placeholder="đơn vị USD"
                  label="Giá"
                  outlined
                  type="number"
                  :disabled="!fee.not_save"
                ></v-text-field>
              </td>
              <td>
                <div v-if="fee.not_save" style="display: flex; justify-content: space-between">
                  <v-btn
                    class="btn-package closed"
                    icon
                    color="success"
                    @click="removeOrderFee(idx)"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon light v-bind="attrs" v-on="on"
                        >mdi-close</v-icon
                        >
                      </template>
                      <span>Xoá chi phí</span>
                    </v-tooltip>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="btn-package check" icon color="success" @click="onClickSaveFee(fee, idx)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon light v-bind="attrs" v-on="on"
                        >mdi-check</v-icon
                        >
                      </template>
                      <span>Thêm chi phí</span>
                    </v-tooltip>
                  </v-btn>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: "AddFee",
  props: {
    selectedItem: {
      type: Object,
    },
    onClose: {
      type: Function,
    }
  },
  data: () => ({
    fees: [],
  }),
  computed: {

  },
  methods: {
    ...mapActions({
      createBigContainerFee: 'big_container_fee/create',
    }),
    removeOrderFee(e) {
      if (this.fees.length > 0) {
        this.fees.splice(e, 1)
      }
      this.$forceUpdate();
    },
    addBigContainerFee() {
      if (!this.fees) this.fees = [];
      this.fees.push({
        fee_name: "",
        fee_amount: 0,
        not_save: true,
      });
      this.$forceUpdate();
    },
    onClickSaveFee(fee, index) {
      if(fee.fee_name.trim().length === 0) {
        this.noticeError('Fee name required!');
        return;
      }
      this.createBigContainerFee({...fee, big_container_id: this.selectedItem.id}).then(() => {
        this.fees[index]['not_save'] = false;
        this.noticeSuccess('Big container fee created!');
      });
    }
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem && this.selectedItem.big_container_fees) {
          this.fees = this.selectedItem.big_container_fees;
          this.fees.forEach(f => {
            if(typeof f.not_save === "undefined") f.not_save = false;
          })
        }
      },
      deep: true,
      immediate: true
    },
  }
};
</script>
<style scoped lang="scss">
.add-big-container-fee {
  background: #fff;
  padding: 10px 15px;
}
.closed {
  /* position: absolute;
  right: -1rem; */
  background: var(--v-error-lighten1);
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
.check {
  background: #4caf4f;
  color: #fff !important;
  width: 2rem;
  height: 2rem;
}
</style>
<style lang="scss">
.table-order-fees {
  .v-text-field__details {
    display: none;
  }
}
</style>
