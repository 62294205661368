<template>
  <v-card>
    <v-card-title>Export Big Container</v-card-title>
    <v-card-actions>
      <v-radio-group v-model="exportOptionSelected">
        <v-radio
          v-for="o in optionExport"
          :key="o.value"
          :label="o.label"
          :value="o.value"
        ></v-radio>
      </v-radio-group>
    </v-card-actions>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="onClickExport">Export</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import indexMixin from './indexMixin';

export default {
  name: 'Export',
  props: ['onCancel', 'option', 'picked'],
  mixins: [indexMixin],
  data: () => ({
    importFile: null,
    exportOptionSelected: 'default',
    showCreatedAtOrderDatePicker: false,
    searchFieldExport: {},
    optionExport: [
      {
        value: 'default',
        label: "Export default",
      },
      {
        value: 'manifest',
        label: "Export manifest",
      }
    ]
  }),
  methods: {
    ...mapActions({
      exportListItems: 'big_container/exportItem',
      exportManifestItems: 'big_container/exportManifestItem',
    }),
    exportItems() {
      if (this.picked.length === 0) return this.noticeWarning('Item not found');
      let ids = [];
      this.picked.forEach(item => {
        ids.push(item.id)
      });

      this.exportListItems({
        ids: ids,
        successCallback: (res) => {
          console.log(res)
        },
        errCallback: (res) => {
          console.log(res)
        },
      })
    },
    exportItemsManifest() {
      if (this.picked.length === 0) return this.noticeWarning('Item not found');
      let ids = [];
      this.picked.forEach(item => {
        ids.push(item.id)
      });

      this.exportManifestItems({
        ids: ids,
        successCallback: (res) => {
          console.log(res)
        },
        errCallback: (res) => {
          console.log(res)
        },
      })
    },
    async onClickExport() {
      if (this.exportOptionSelected === 'default') await this.exportItems();
      else await this.exportItemsManifest()
    }
  },
}
</script>
