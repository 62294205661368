var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-big-container-fee"},[_c('div',{staticClass:"title-col title-order font-weight-bold color-main my-4"},[_vm._v(" Thêm phí vận chuyển "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"font-size":"2.25rem","margin-left":"1rem"},attrs:{"color":"success"},on:{"click":_vm.addBigContainerFee}},'v-icon',attrs,false),on),[_vm._v("mdi-plus ")])]}}])},[_c('span',[_vm._v("Ấn vào đây để thêm phí vận chuyển")])])],1),(_vm.fees.length > 0)?_c('div',{staticClass:"py-3 px-3 table-order-fees"},[_c('v-row',{staticClass:"v-data-table v-card v-sheet v-sheet--outlined theme--light"},[_c('v-col',{staticClass:"v-data-table__wrapper",attrs:{"cols":"12"}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Fee name")]),_c('th',[_vm._v("Amount")]),_c('th')])]),_c('tbody',_vm._l((_vm.fees),function(fee,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(idx + 1))]),_c('td',[_c('v-text-field',{attrs:{"dense":"","label":"Tên chi phí","outlined":"","disabled":!fee.not_save},model:{value:(fee.fee_name),callback:function ($$v) {_vm.$set(fee, "fee_name", $$v)},expression:"fee.fee_name"}})],1),_c('td',[_c('v-text-field',{attrs:{"dense":"","placeholder":"đơn vị USD","label":"Giá","outlined":"","type":"number","disabled":!fee.not_save},model:{value:(fee.fee_amount),callback:function ($$v) {_vm.$set(fee, "fee_amount", $$v)},expression:"fee.fee_amount"}})],1),_c('td',[(fee.not_save)?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-btn',{staticClass:"btn-package closed",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.removeOrderFee(idx)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"light":""}},'v-icon',attrs,false),on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v("Xoá chi phí")])])],1),_c('v-spacer'),_c('v-btn',{staticClass:"btn-package check",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.onClickSaveFee(fee, idx)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"light":""}},'v-icon',attrs,false),on),[_vm._v("mdi-check")])]}}],null,true)},[_c('span',[_vm._v("Thêm chi phí")])])],1)],1):_vm._e()])])}),0)])])],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"mx-0 pa-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.onClose}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }